import { AbstractControl, Validators } from "@angular/forms";
import { Logger } from "src/app/providers/logger";

import { Child } from "../../models/people/child.class";
import {
  FamilyCard,
  FamilyCardService,
} from "../../services/family-card.service";

/** check if passwords with given FormControlObject.key match or not */
export function identical_password_validator(key_pw1: string, key_pw2: string) {
  return (control: AbstractControl): { identical_passwords: true } | null => {
    const pw1 = control.get(key_pw1).value;
    const pw2 = control.get(key_pw2).value;
    return pw1 === pw2 ? null : { identical_passwords: true };
  };
}

/**
 * checkbox/toggle has to be true.
 *
 * Wrapped into custom function because Validators.requiredTrue would return
 * {required: true} on error, same as Validators.required.
 * I'd like to be able to differentiate their error messages.
 */
export function required_true(
  control: AbstractControl
): { required_true: true } | null {
  return Validators.requiredTrue(control)?.required
    ? { required_true: true }
    : null;
}

/**
 * email has to be correctly formatted
 *
 * Wrapped into custom function because Validators.email is not strict enough.
 * An email should include an authority at the end.
 *
 * @example
 * max@gmail is now invalid
 * max@gmail.com is valid
 */
export function email(control: AbstractControl): { email: true } | null {
  if (Validators.email(control)?.email) {
    return { email: true };
  }

  return Validators.pattern(/^.+@.+\..+/)(control)?.pattern
    ? { email: true }
    : null;
}

/** check access code format */
export function access_code_validator(
  control: AbstractControl
): { access_code: true } | null {
  const triple = "[A-Za-z0-9]{3}";
  const regex = new RegExp(`^(?:${triple}-){3}${triple}$`);
  return Validators.pattern(regex)(control)?.pattern
    ? { access_code: true }
    : null;
}

/**
 * Async validator for the familycard. Performs http-calls on occasion to check validity.
 * Use on FormGroup with controls 'first_name', 'last_name', 'familycard'
*/
export function family_card_validator(service: FamilyCardService) {

  return async (control: AbstractControl): Promise<{ familycard: true } | null> => {
    // first check
    const error: { familycard: true } = { familycard: true };
    const familycard = '' + (control.value.familycard || '');

    if (!familycard || familycard.length !== 4) {
      return null;
    }
    if (control.errors?.first_name || control.errors?.last_name) {
      return error;
    }

    // further declarations
    const first_name: string = control.value.first_name;
    const last_name: string = control.value.last_name;

    // run check
    return service
      .check(
        { first_name, last_name } as Child,
        { family_card: familycard } as FamilyCard
      )
      .then((valid) => (valid ? null : error))
      .catch(() => error);
  };
}

/** for validating a bic of a bank syntaxwise */
export function bic_validator(
  control: AbstractControl
): { bic: true } | null {
  const regex = /^\w{6}[\d\w]{2}([\d\w]{3})?$/;
  return Validators.pattern(regex)(control)?.pattern
    ? { bic: true }
    : null;
}


/** for validating required after .trim() was called on the value */
export function nonempty_after_trim_validator(
  control: AbstractControl
): { nonempty_after_trim: true } | null {
  return control.value?.trim()?.length === 0
    ? { nonempty_after_trim: true }
    : null;
}


/** for testing the form.component only. Is alwys invalid. */
export function test_validator(logging = true) {
  return (control: AbstractControl): { test: true } => {
    if (logging) {
      Logger.debug("test-validator ran on", { control });
    }
    return { test: true };
  };
}
