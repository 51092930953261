<ion-content>

  <app-info-banner *ngIf="first_login">
    Sie haben Ihre E-Mail erfolgreich verifiziert. Bitte geben Sie abschließend die gefragten Daten an:
  </app-info-banner>

  <!-- segments -->
  <nav class="segment-buttons">
    <app-button class="segment-btn" [color]="segment_model === Tab.ACCOUNT ? 'secondary' : 'primary'" (clicked)="segment_model = Tab.ACCOUNT" data-cy="account_tab_button">
      {{ config_data.wording.ich }}
    </app-button>
    <app-button *ngIf="config_data.booking_settings?.parent_booking_allowed && config_data.booking_settings?.second_legal_guardian_allowed"
      class="segment-btn" [color]="segment_model === Tab.GUARDIAN ? 'secondary' : 'primary'" (clicked)="segment_model = Tab.GUARDIAN" data-cy="second_legal_guardian_tab_button">
      {{ config_data.wording.second_guardian }}
    </app-button>
    <app-button class="segment-btn" [color]="segment_model === Tab.CHILDREN ? 'secondary' : 'primary'" (clicked)="segment_model = Tab.CHILDREN" data-cy="child_tab_button">
      {{ config_data.wording.Kinder }}
    </app-button>
    <app-button *ngIf="display_payment_tab()" class="segment-btn" [color]="segment_model === Tab.PAYMENT ? 'secondary' : 'primary'" (clicked)="segment_model = Tab.PAYMENT" data-cy="payment_tab_button">
      Zahlungsinformationen
    </app-button>
  </nav>

  <!-- Account -->
  <main class="page-container">
    <form *ngIf="segment_model === Tab.ACCOUNT && account_form" [formGroup]="account_form" (ngSubmit)="submit_account()" novalidate>
      <h1 class="title">{{ account_data.first_name && account_data.last_name ?
        'Hallo ' + account_data.first_name + ' ' +  account_data.last_name : 'Mein Account'}}</h1>

      <!-- first_name -->
      <div *appLet="account_form.controls.first_name as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Vorname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_first_name"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- last_name -->
      <div *appLet="account_form.controls.last_name as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Nachname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_last_name"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- gender -->
      <div *appLet="account_form.controls.gender as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Geschlecht<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-select [formControl]="field" interface="popover" data-cy="account_gender">
            <ion-select-option [value]="FeriproGender.FEMALE">weiblich</ion-select-option>
            <ion-select-option [value]="FeriproGender.MALE">männlich</ion-select-option>
            <ion-select-option [value]="FeriproGender.DIVERSE">divers</ion-select-option>
          </ion-select>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- date of birth -->
      <div *appLet="account_form.controls.date_of_birth_german_format as field">

        <ion-item *ngIf="!field.disabled" >
          <ion-label position="stacked">Geburtsdatum<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input
            [formControl]="field"
            placeholder="tt.mm.jjjj"
            (ionChange)="update_dob(account_form.controls.date_of_birth_german_format, account_form.controls.date_of_birth)"
            data-cy="account_date_of_birth"
          ></ion-input>
          <ion-button fill="clear" id="account-dob" slot="end" class="trigger-popover-btn">
            <ion-icon name="calendar"></ion-icon>
          </ion-button>
          <ion-popover trigger="account-dob" show-backdrop="false">
            <ng-template>
              <ion-datetime
                #popoverDate
                formControlName="date_of_birth"
                locale="de-DE"
                first-day-of-week="1"
                presentation="date"
                (ionChange)="update_dob(account_form.controls.date_of_birth, account_form.controls.date_of_birth_german_format)"
              >
                <ion-label slot="title">Geburtsdatum</ion-label>
              </ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors" 
          [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Hier können Sie ein Datum im Format tt.mm.jjjj angeben'}}">
        </ng-container>
      </div>
      <!-- /date of birth -->

      <ng-container *ngIf="!config_data.hide_disabled_in_profile">
        <!-- disabled -->
        <div *appLet="account_form.controls.disabled as field">
          <ion-item *ngIf="!field.disabled">
            <ion-label>Die Person ist durch eine Behinderung eingschränkt.</ion-label>
            <ion-checkbox slot="start" [formControl]="field" data-cy="account_handicap"></ion-checkbox>
          </ion-item>
          <ng-container
            [ngTemplateOutlet]="errors"
            [ngTemplateOutletContext]="{field: field}">
          </ng-container>
        </div>

        <!-- handicap_selection -->
        <div *appLet="account_form.controls.handicap_selection as field">
          <ion-item *ngIf="!field.disabled && account_form.value.disabled">
            <ion-label position="stacked">Behinderung/Beeinträchtigung<strong *ngIf="is_field_required(field)">*</strong></ion-label>
            <ion-select [formControl]="field" interface="popover" data-cy="account_handicap_selection" multiple>
              <ion-select-option *ngFor="let disability of all_disabilities" [value]="disability.value">{{ disability.label }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ng-container
            [ngTemplateOutlet]="errors"
            [ngTemplateOutletContext]="{field: field}">
          </ng-container>
        </div>

        <!-- handicap -->
        <div *appLet="account_form.controls.handicap as field">

          <ion-item *ngIf="!field.disabled && account_form.value.disabled">
            <ion-label position="stacked">Details zur Behinderung/Beeinträchtigung<strong *ngIf="is_field_required(field)">*</strong></ion-label>
            <ion-input [formControl]="field" data-cy="account_disabled"></ion-input>
          </ion-item>
          <ng-container
            [ngTemplateOutlet]="errors"
            [ngTemplateOutletContext]="{field: field}">
          </ng-container>
        </div>
      </ng-container>


      <h2>Adresse</h2>

      <!-- street -->
      <div *appLet="account_form.controls.street as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Straße<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_street"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie Ihre Straße mit Hausnummer an!'}}">
        </ng-container>
      </div>

      <!-- zip_code -->
      <div *appLet="account_form.controls.zip_code as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">PLZ<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_zip_code"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie eine gültige PLZ an!'}}">
        </ng-container>
      </div>

      <!-- city -->
      <div *appLet="account_form.controls.city as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Stadt<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_city"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- district -->
      <div *appLet="account_form.controls.district as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Kreis<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_district"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- phone -->
      <div *appLet="account_form.controls.phone as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Telefon<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field" data-cy="account_phone"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field, messages: {maxLength: 'Die angegebene Telefonnummer ist zu lang. (max. 50 Zeichen)'}}">
        </ng-container>
      </div>

      <section
        *ngIf="account_questions?.length"
        data-cy="account_questions_section"
      >
        <h2>Fragen</h2>

        <ng-container *ngFor="let question of get_unique_questions(account_questions)">

          <div *appLet="account_form.get('question_'+question.question.id) as field">

            <ion-item>
              <ion-label position="stacked">{{ question.question.label }}<strong *ngIf="is_field_required(field)">*</strong></ion-label>

              <ng-container [ngSwitch]="question.question.type">

                <ion-input *ngSwitchCase="CustomQuestionType.TEXT" [formControlName]="'question_'+question.question.id"></ion-input>

                <ion-select *ngSwitchCase="CustomQuestionType.ANSWER_CHOICES" interface="popover" [formControlName]="'question_'+question.question.id">
                  <ion-select-option *ngIf="!question.question.required" value="">-</ion-select-option>
                  <ion-select-option *ngFor="let option of question.question.choices" [value]="option" data-cy="question-select-option">{{ option }}</ion-select-option>
                </ion-select>

                <ion-select *ngSwitchCase="CustomQuestionType.YES_OR_NO" interface="popover" [formControlName]="'question_'+question.question.id">
                  <ion-select-option *ngIf="!question.question.required" value="">-</ion-select-option>
                  <ion-select-option value="Ja">Ja</ion-select-option>
                  <ion-select-option value="Nein">Nein</ion-select-option>
                </ion-select>
              </ng-container>

            </ion-item>

            <!-- errors -->
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field}">
            </ng-container>

            <!-- optional information -->
            <div class="info-container" *ngIf="question.question.description">
              <ion-icon name="information-circle-outline" style="pointer-events: none;"></ion-icon>
              <span [innerHtml]="question.question.description"></span>
            </div>

          </div>
        </ng-container>
      </section>


      <div class="submit">
        <app-form-error-message *ngIf="show_all_errors && !account_form.valid">
          Bitte beheben Sie alle Fehler um fortzufahren.
        </app-form-error-message>

        <app-button type="submit">Speichern</app-button>
      </div>
    </form>

    <!-- 2nd legal guardian -->
    <form *ngIf="segment_model === Tab.GUARDIAN && second_legal_guardian_form" [formGroup]="second_legal_guardian_form" (ngSubmit)="submit_second_legal_guardian()" novalidate>
      <h1 class="title">{{ config_data.wording.second_guardian }}</h1>

      <!-- first_name -->
      <div *appLet="second_legal_guardian_form.controls.first_name as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Vorname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- last_name -->
      <div *appLet="second_legal_guardian_form.controls.last_name as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Nachname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- gender -->
      <div *appLet="second_legal_guardian_form.controls.gender as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Geschlecht<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-select [formControl]="field" interface="popover">
            <ion-select-option [value]="FeriproGender.FEMALE">weiblich</ion-select-option>
            <ion-select-option [value]="FeriproGender.MALE">männlich</ion-select-option>
            <ion-select-option [value]="FeriproGender.DIVERSE">divers</ion-select-option>
          </ion-select>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- date of birth -->
      <div *appLet="second_legal_guardian_form.controls.date_of_birth_german_format as field">

        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Geburtsdatum<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input
            [formControl]="field"
            placeholder="tt.mm.jjjj"
            (ionChange)="update_dob(field, second_legal_guardian_form.controls.date_of_birth)"
          ></ion-input>
          <ion-button fill="clear" id="slg-dob" slot="end" class="trigger-popover-btn">
            <ion-icon name="calendar"></ion-icon>
          </ion-button>
          <ion-popover trigger="slg-dob" show-backdrop="false">
            <ng-template>
              <ion-datetime
                #popoverDate
                formControlName="date_of_birth"
                locale="de-DE"
                first-day-of-week="1"
                presentation="date"
                (ionChange)="update_dob(second_legal_guardian_form.controls.date_of_birth, field)"
              >
                <ion-label slot="title">Geburtsdatum</ion-label>
              </ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors" 
          [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Hier können Sie ein Datum im Format tt.mm.jjjj angeben'}}">
        </ng-container>
      </div>
      <!-- /date of birth -->

      <ng-container *ngIf="!config_data.hide_disabled_in_profile">
        <!-- disabled -->
        <div *appLet="second_legal_guardian_form.controls.disabled as field">
          <ion-item *ngIf="!field.disabled">
            <ion-label>Die Person ist durch eine Behinderung eingschränkt.</ion-label>
            <ion-checkbox slot="start" [formControl]="field"></ion-checkbox>
          </ion-item>
          <ng-container
            [ngTemplateOutlet]="errors"
            [ngTemplateOutletContext]="{field: field}">
          </ng-container>
        </div>

        <!-- handicap_selection -->
        <div *appLet="second_legal_guardian_form.controls.handicap_selection as field">
          <ion-item *ngIf="!field.disabled && second_legal_guardian_form.value.disabled">
            <ion-label position="stacked">Behinderung/Beeinträchtigung<strong *ngIf="is_field_required(field)">*</strong></ion-label>
            <ion-select [formControl]="field" interface="popover" multiple>
              <ion-select-option *ngFor="let disability of all_disabilities" [value]="disability.value">{{ disability.label }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ng-container
            [ngTemplateOutlet]="errors"
            [ngTemplateOutletContext]="{field: field}">
          </ng-container>
        </div>

        <!-- handicap -->
        <div *appLet="second_legal_guardian_form.controls.handicap as field">

          <ion-item *ngIf="!field.disabled && second_legal_guardian_form.value.disabled">
            <ion-label position="stacked">Details zur Behinderung/Beeinträchtigung<strong *ngIf="is_field_required(field)">*</strong></ion-label>
            <ion-input [formControl]="field"></ion-input>
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field}">
            </ng-container>
          </ion-item>
        </div>
      </ng-container>


      <h2>Adresse</h2>

      <!-- street -->
      <div *appLet="second_legal_guardian_form.controls.street as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Straße<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie Ihre Straße mit Hausnummer an!'}}">
        </ng-container>
      </div>

      <!-- zip_code -->
      <div *appLet="second_legal_guardian_form.controls.zip_code as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">PLZ<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie eine gültige PLZ an!'}}">
        </ng-container>
      </div>

      <!-- city -->
      <div *appLet="second_legal_guardian_form.controls.city as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Stadt<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- district -->
      <div *appLet="second_legal_guardian_form.controls.district as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Kreis<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- phone -->
      <div *appLet="second_legal_guardian_form.controls.phone as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">Telefon<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field, messages: {maxLength: 'Die angegebene Telefonnummer ist zu lang. (max. 50 Zeichen)'}}">
        </ng-container>
      </div>

      <section *ngIf="second_legal_guardian_questions?.length">
        <h2>Fragen</h2>

        <ng-container *ngFor="let question of get_unique_questions(second_legal_guardian_questions)">

          <div *appLet="second_legal_guardian_form.get('question_'+question.question.id) as field">

            <ion-item>
              <ion-label position="stacked">{{ question.question.label }}<strong *ngIf="is_field_required(field)">*</strong></ion-label>

              <ng-container [ngSwitch]="question.question.type">

                <ion-input *ngSwitchCase="CustomQuestionType.TEXT" [formControlName]="'question_'+question.question.id"></ion-input>

                <ion-select *ngSwitchCase="CustomQuestionType.ANSWER_CHOICES" interface="popover" [formControlName]="'question_'+question.question.id">
                  <ion-select-option *ngIf="!question.question.required" value="">-</ion-select-option>
                  <ion-select-option *ngFor="let option of question.question.choices" [value]="option">{{ option }}</ion-select-option>
                </ion-select>

                <ion-select *ngSwitchCase="CustomQuestionType.YES_OR_NO" interface="popover" [formControlName]="'question_'+question.question.id">
                  <ion-select-option *ngIf="!question.question.required" value="">-</ion-select-option>
                  <ion-select-option value="Ja">Ja</ion-select-option>
                  <ion-select-option value="Nein">Nein</ion-select-option>
                </ion-select>
              </ng-container>

            </ion-item>

            <!-- errors -->
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field}">
            </ng-container>

            <!-- optional information -->
            <div class="info-container" *ngIf="question.question.description">
              <ion-icon name="information-circle-outline" style="pointer-events: none;"></ion-icon>
              <span [innerHtml]="question.question.description"></span>
            </div>

          </div>
        </ng-container>
      </section>


      <div class="submit">
        <app-form-error-message *ngIf="show_all_errors && !second_legal_guardian_form.valid">
          Bitte beheben Sie alle Fehler um fortzufahren.
        </app-form-error-message>

        <app-button type="submit">Speichern</app-button>
      </div>
      <app-button expand="full" class="delete-btn" color="danger" type="button" *ngIf="completed_forms.has(AccountType.SECOND_LEGAL_GUARDIAN)" (clicked)="delete_second_legal_guardian()">
        Löschen
      </app-button>
    </form>


    <!-- Children -->
    <ng-container *ngIf="segment_model === Tab.CHILDREN">
      <h1 class="title">{{ config_data.wording.Kinder }} hinzufügen</h1>

      <form *ngIf="children_form" [formGroup]="children_form" (ngSubmit)="submit_children()" novalidate>

        <ion-accordion-group>
          <ion-accordion *ngFor="let child of children_form.controls; let index=index" [formGroup]="child" class="child" toggleIcon="caret-down" data-cy="child_accordion_element">
            <ion-item slot="header">
              <ion-icon *ngIf="show_all_errors && !child.valid" slot="start" name="information-circle-outline" color="danger"></ion-icon>

              <ion-label *ngIf="child.value.first_name || child.value.last_name">{{ child.value.first_name }} {{ child.value.last_name }}</ion-label>
              <ion-label *ngIf="!child.value.first_name && !child.value.last_name"><em>unbekannt</em></ion-label>

              <ion-icon slot="end" name="trash-outline" color="danger" (click)="$event.stopImmediatePropagation(); delete_child(child)" data-cy="child_delete_button"></ion-icon>
            </ion-item>
            <ion-card slot="content">

              <!-- first_name -->
              <div *appLet="child.controls.first_name as field">
                <ion-item *ngIf="!field.disabled">
                  <ion-label position="stacked">Vorname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                  <ion-input [formControl]="field" data-cy="child_first_name"></ion-input>
                </ion-item>
                <ng-container
                  [ngTemplateOutlet]="errors"
                  [ngTemplateOutletContext]="{field: field}">
                </ng-container>
              </div>

              <!-- last_name -->
              <div *appLet="child.controls.last_name as field">
                <ion-item *ngIf="!field.disabled">
                  <ion-label position="stacked">Nachname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                  <ion-input [formControl]="field" data-cy="child_last_name"></ion-input>
                </ion-item>
                <ng-container
                  [ngTemplateOutlet]="errors"
                  [ngTemplateOutletContext]="{field: field}">
                </ng-container>
              </div>

              <!-- gender -->
              <div *appLet="child.controls.gender as field">
                <ion-item *ngIf="!field.disabled">
                  <ion-label position="stacked">Geschlecht<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                  <ion-select [formControl]="field" interface="popover" data-cy="child_gender">
                    <ion-select-option [value]="FeriproGender.FEMALE">weiblich</ion-select-option>
                    <ion-select-option [value]="FeriproGender.MALE">männlich</ion-select-option>
                    <ion-select-option [value]="FeriproGender.DIVERSE">divers</ion-select-option>
                  </ion-select>
                </ion-item>
                <ng-container
                  [ngTemplateOutlet]="errors"
                  [ngTemplateOutletContext]="{field: field}">
                </ng-container>
              </div>

              <!-- date of birth -->
              <div *appLet="child.controls.date_of_birth_german_format as field">

                <ion-item *ngIf="!field.disabled">
                  <ion-label position="stacked">Geburtsdatum<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                  <ion-input
                    [formControl]="field"
                    placeholder="tt.mm.jjjj"
                    (ionChange)="update_dob(field, child.controls.date_of_birth)"
                    data-cy="child_date_of_birth"
                  ></ion-input>
                  <ion-button fill="clear" [id]="'child-dob-'+index" slot="end" class="trigger-popover-btn">
                    <ion-icon name="calendar"></ion-icon>
                  </ion-button>
                  <ion-popover [trigger]="'child-dob-'+index" show-backdrop="false">
                    <ng-template>
                      <ion-datetime
                        #popoverDate
                        formControlName="date_of_birth"
                        locale="de-DE"
                        first-day-of-week="1"
                        presentation="date"
                        (ionChange)="update_dob(child.controls.date_of_birth, field)"
                      >
                        <ion-label slot="title">Geburtsdatum</ion-label>
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                </ion-item>
                <ng-container
                  [ngTemplateOutlet]="errors" 
                  [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Hier können Sie ein Datum im Format tt.mm.jjjj angeben'}}">
                </ng-container>
              </div>
              <!-- /date of birth -->

              <ng-container *ngIf="!config_data.hide_disabled_in_profile">
                <!-- disabled -->
                <div *appLet="child.controls.disabled as field">
                  <ion-item *ngIf="!field.disabled">
                    <ion-label>Die Person ist durch eine Behinderung eingschränkt.</ion-label>
                    <ion-checkbox slot="start" [formControl]="field" data-cy="child_disabled"></ion-checkbox>
                  </ion-item>
                  <ng-container
                    [ngTemplateOutlet]="errors"
                    [ngTemplateOutletContext]="{field: field}">
                  </ng-container>
                </div>

                <!-- handicap_selection -->
                <div *appLet="child.controls.handicap_selection as field">
                  <ion-item *ngIf="!field.disabled && child.value.disabled">
                    <ion-label position="stacked">Behinderung/Beeinträchtigung<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                    <ion-select [formControl]="field" interface="popover" multiple>
                      <ion-select-option *ngFor="let disability of all_disabilities" [value]="disability.value">{{ disability.label }}</ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ng-container
                    [ngTemplateOutlet]="errors"
                    [ngTemplateOutletContext]="{field: field}">
                  </ng-container>
                </div>

                <!-- handicap -->
                <ng-container *appLet="child.controls.handicap as field">

                  <ion-item *ngIf="!field.disabled && child.value.disabled">
                    <ion-label position="stacked">Details zur Behinderung/Beeinträchtigung<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                    <ion-input [formControl]="field" data-cy="child_handicap"></ion-input>
                  </ion-item>
                  <ng-container
                    [ngTemplateOutlet]="errors"
                    [ngTemplateOutletContext]="{field: field}">
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- school -->
              <ng-container *ngIf="!config_data.hide_school_in_profile">
                <div *appLet="child.controls.school as field">
                  <ion-item *ngIf="!field.disabled">
                    <ion-label position="stacked">Schule<strong>*</strong></ion-label>
                    <ion-input [formControl]="field" data-cy="child_school"></ion-input>
                  </ion-item>
                  <ng-container
                    [ngTemplateOutlet]="errors"
                    [ngTemplateOutletContext]="{field: field}">
                  </ng-container>
                </div>
              </ng-container>


              <!-- familycard -->
              <ng-container *appLet="child.controls.familycard as field">
                <div *ngIf="!field.disabled">

                  <ion-item>
                    <ion-label position="stacked">Wiesbadener Freizeitkarte<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                    <ion-input placeholder="0000" [formControl]="field" data-cy="child_familycard"></ion-input>
                  </ion-item>

                  <!-- errors -->
                  <ng-container
                    [ngTemplateOutlet]="errors"
                    [ngTemplateOutletContext]="{field: field, messages: { minlength: 'Bitte geben Sie 4 Stellen an', maxlength: 'Bitte geben Sie 4 Stellen an' }}">
                  </ng-container>

                  <!-- optional information -->
                  <div class="info-container" title="Die Wiesbadener Freizeitkarte richtet sich an
                    Wiesbadener Familien mit mindestens drei Kindern bis zum Alter von 18 Jahren,
                    Alleinerziehende mit Kindern bis zum Alter von 18 Jahren und
                    Empfänger von Arbeitslosengeld II, Wohngeld und Kinderzuschlag mit Kindern bis zum Alter von 18 Jahren.

                    Mit der Wiesbadener Freizeitkarte kosten die Veranstaltungen im Ferienprogramm nur 50% des regulären Preises.">

                    <ion-icon name="information-circle-outline" style="pointer-events: none;"></ion-icon>
                    <a href='https://www.wiesbaden.de/freizeitkarte' target="_blank" rel="noreferrer">Freizeitkarte beantragen</a>
                  </div>
                </div>
              </ng-container>

              <ion-accordion-group
                class="address"
                [formGroup]="child.controls.address"
                *appLet="child.controls.address as address"
                [value]="address.initial_open ? 'address' : ''"
                (ionChange)="toggle_child_address($event, address)"
                data-cy="child_toggle_address_form"
              >
                <ion-accordion value="address">
                  <ion-item slot="header">
                    <h2><ion-label>Abweichende Adresse <small>(optional)</small></ion-label></h2>

                    <ion-icon class="ion-accordion-toggle-icon" name="add" slot="end"></ion-icon>
                  </ion-item>
                  <div slot="content">

                    <!-- street -->
                    <div *appLet="address.controls.street as field">
                      <ion-item>
                        <ion-label position="stacked">Straße<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                        <ion-input [formControl]="field" data-cy="child_street"></ion-input>
                      </ion-item>
                      <ng-container
                        [ngTemplateOutlet]="errors"
                        [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie Ihre Straße mit Hausnummer an!'}}">
                      </ng-container>
                    </div>

                    <!-- zip_code -->
                    <div *appLet="address.controls.zip_code as field">
                      <ion-item>
                        <ion-label position="stacked">PLZ<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                        <ion-input [formControl]="field" data-cy="child_zip_code"></ion-input>
                      </ion-item>
                      <ng-container
                        [ngTemplateOutlet]="errors"
                        [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie eine gültige PLZ an!'}}">
                      </ng-container>
                    </div>

                    <!-- city -->
                    <div *appLet="address.controls.city as field">
                      <ion-item>
                        <ion-label position="stacked">Stadt<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                        <ion-input [formControl]="field" data-cy="child_city"></ion-input>
                      </ion-item>
                      <ng-container
                        [ngTemplateOutlet]="errors"
                        [ngTemplateOutletContext]="{field: field}">
                      </ng-container>
                    </div>

                    <!-- district -->
                    <div *appLet="address.controls.district as field">
                      <ion-item>
                        <ion-label position="stacked">Kreis<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                        <ion-input [formControl]="field" data-cy="child_district"></ion-input>
                      </ion-item>

                      <ng-container
                        [ngTemplateOutlet]="errors"
                        [ngTemplateOutletContext]="{field: field}">
                      </ng-container>
                    </div>

                    <!-- phone -->
                    <div *appLet="address.controls.phone as field">
                      <ion-item>
                        <ion-label position="stacked">Telefon<strong *ngIf="is_field_required(field)">*</strong></ion-label>
                        <ion-input [formControl]="field" data-cy="child_phone"></ion-input>
                      </ion-item>
                      <ng-container
                        [ngTemplateOutlet]="errors"
                        [ngTemplateOutletContext]="{field: field, messages: {maxLength: 'Die angegebene Telefonnummer ist zu lang. (max. 50 Zeichen)'}}">
                      </ng-container>
                    </div>
                  </div>
                </ion-accordion>
              </ion-accordion-group>

              <section
                *ngIf="child_questions[child.value.pk]?.length"
                data-cy="child_questions_section"
              >
                <h2>Fragen</h2>

                <ng-container *ngFor="let question of get_unique_questions(child_questions[child.value.pk])">

                  <div *appLet="child.get('question_'+question.question.id) as field">

                    <ion-item>
                      <ion-label position="stacked">{{ question.question.label }}<strong *ngIf="is_field_required(field)">*</strong></ion-label>

                      <ng-container [ngSwitch]="question.question.type">

                        <ion-input *ngSwitchCase="CustomQuestionType.TEXT" [formControlName]="'question_'+question.question.id"></ion-input>

                        <ion-select *ngSwitchCase="CustomQuestionType.ANSWER_CHOICES" interface="popover" [formControlName]="'question_'+question.question.id">
                          <ion-select-option *ngIf="!question.question.required" value="">-</ion-select-option>
                          <ion-select-option *ngFor="let option of question.question.choices" [value]="option">{{ option }}</ion-select-option>
                        </ion-select>

                        <ion-select *ngSwitchCase="CustomQuestionType.YES_OR_NO" interface="popover" [formControlName]="'question_'+question.question.id">
                          <ion-select-option *ngIf="!question.question.required" value="">-</ion-select-option>
                          <ion-select-option value="Ja">Ja</ion-select-option>
                          <ion-select-option value="Nein">Nein</ion-select-option>
                        </ion-select>
                      </ng-container>

                    </ion-item>

                    <!-- errors -->
                    <ng-container
                      [ngTemplateOutlet]="errors"
                      [ngTemplateOutletContext]="{field: field}">
                    </ng-container>

                    <!-- optional information -->
                    <div class="info-container" *ngIf="question.question.description">
                      <ion-icon name="information-circle-outline" style="pointer-events: none;"></ion-icon>
                      <span [innerHtml]="question.question.description"></span>
                    </div>

                  </div>
                </ng-container>
              </section>
            </ion-card>
          </ion-accordion>
        </ion-accordion-group>


        <div class="submit" *ngIf="children_form.value?.length">
          <app-form-error-message *ngIf="show_all_errors && !children_form.valid">
            Bei Angaben mancher {{ config_data.wording.Kinder }} sind Fehler aufgetreten. Bitte beheben Sie alle Fehler um fortzufahren.
          </app-form-error-message>

          <app-button type="submit" data-cy="child_save_button">Speichern</app-button>
        </div>

        <app-button class="add-child-btn" (clicked)="add_child()">
          <ion-icon slot="start" name="add"></ion-icon>
          {{ config_data.wording.Kind }} hinzufügen
        </app-button>
      </form>
    </ng-container>


    <!-- Payment -->
    <form *ngIf="segment_model === Tab.PAYMENT && payment_form" [formGroup]="payment_form" (ngSubmit)="submit_payment()" novalidate>
      <h1 class="title">Zahlungsinformationen</h1>

      <!-- iban -->
      <div *appLet="payment_form.controls.iban as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">IBAN<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <div class="info-container">
          <ion-icon name="information-circle-outline" style="pointer-events: none;"></ion-icon>
          <span>Die IBAN Ihres Kontos finden Sie auf Kontoauszügen, der Bankkarte oder der Website Ihrer Bank.</span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- bic -->
      <div *appLet="payment_form.controls.bic as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label position="stacked">BIC<strong *ngIf="is_field_required(field)">*</strong></ion-label>
          <ion-input [formControl]="field"></ion-input>
        </ion-item>
        <div class="info-container">
          <ion-icon name="information-circle-outline" style="pointer-events: none;"></ion-icon>
          <span>Die BIC Ihrer Bank finden Sie auf Kontoauszügen, der Bankkarte oder der Website Ihrer Bank.</span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>

      <!-- my_bank_account -->
      <div *appLet="payment_form.controls.my_bank_account as field">
        <ion-item *ngIf="!field.disabled">
          <ion-label>Ich bin Kontoinhaber</ion-label>
          <ion-checkbox slot="start" [formControl]="field" data-cy="payment_may_bank_account" (ionChange)="update_payment_bank_account_owner()"></ion-checkbox>
        </ion-item>
        <ng-container
          [ngTemplateOutlet]="errors"
          [ngTemplateOutletContext]="{field: field}">
        </ng-container>
      </div>


      <ng-container *ngIf="!payment_form.value.my_bank_account">
        <div [formGroup]="payment_form.controls.bank_account_owner"
          *appLet="payment_form.controls.bank_account_owner as owner">

          <h2>Kontoinhaber</h2>
    
          <!-- first_name -->
          <div *appLet="owner.controls.first_name as field">
            <ion-item *ngIf="!field.disabled">
              <ion-label position="stacked">Vorname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
              <ion-input [formControl]="field"></ion-input>
            </ion-item>
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field}">
            </ng-container>
          </div>
    
          <!-- last_name -->
          <div *appLet="owner.controls.last_name as field">
            <ion-item *ngIf="!field.disabled">
              <ion-label position="stacked">Nachname<strong *ngIf="is_field_required(field)">*</strong></ion-label>
              <ion-input [formControl]="field"></ion-input>
            </ion-item>
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field}">
            </ng-container>
          </div>
    
    
    
          <!-- street -->
          <div *appLet="owner.controls.street as field">
            <ion-item *ngIf="!field.disabled">
              <ion-label position="stacked">Straße<strong *ngIf="is_field_required(field)">*</strong></ion-label>
              <ion-input [formControl]="field" data-cy="payment_street"></ion-input>
            </ion-item>
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie Ihre Straße mit Hausnummer an!'}}">
            </ng-container>
          </div>
    
          <!-- zip_code -->
          <div *appLet="owner.controls.zip_code as field">
            <ion-item *ngIf="!field.disabled">
              <ion-label position="stacked">PLZ<strong *ngIf="is_field_required(field)">*</strong></ion-label>
              <ion-input [formControl]="field" type="number" data-cy="payment_zip_code"></ion-input>
            </ion-item>
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field, messages: {pattern: 'Bitte geben Sie eine gültige PLZ an!'}}">
            </ng-container>
          </div>
    
          <!-- city -->
          <div *appLet="owner.controls.city as field">
            <ion-item *ngIf="!field.disabled">
              <ion-label position="stacked">Stadt<strong *ngIf="is_field_required(field)">*</strong></ion-label>
              <ion-input [formControl]="field" data-cy="payment_city"></ion-input>
            </ion-item>
            <ng-container
              [ngTemplateOutlet]="errors"
              [ngTemplateOutletContext]="{field: field}">
            </ng-container>
          </div>
        </div>
      </ng-container>



      <div class="submit">
        <app-form-error-message *ngIf="show_all_errors && !payment_form.valid">
          Bitte beheben Sie alle Fehler um fortzufahren.
        </app-form-error-message>

        <app-button type="submit">Speichern</app-button>
      </div>
    </form>

    <!-- 1st login-done -->
    <app-button class="first-login-btn" *ngIf="first_login" [disabled]="!completed_forms.has(AccountType.OWNER) || !account_form.valid" (clicked)="complete_registration()">Fertig</app-button>
    
    <app-button *ngIf="!first_login && program_id" class="first-login-btn" [routerLink]="['/events', program_id]">Zurück zu den Veranstaltungen</app-button>
  </main>

  <app-footer></app-footer>
</ion-content>


<ng-template #errors let-field="field" let-messages="messages || {}">
  <div *ngIf="field.errors && (show_all_errors || !field.pristine || field.touched || field.value)">
    <app-form-error-message *ngFor="let error of Object.keys(field.errors)">
      {{ messages[error] || error_messages[error] }}
    </app-form-error-message>
  </div>
</ng-template>
