import { Injectable } from "@angular/core";
import { Event } from "src/app/models/event/event.interface";
import { ParticipantModel } from "src/app/models/registration/participant-model.class";
import { IsRegistrationDateAllowedPipe } from "src/app/pipes/is-registration-date-allowed/is-registration-date-allowed.pipe";
import { AccessCodeService } from "src/app/services/access-code.service";
import { PaymentService } from "src/app/services/payment.service";

import { AsyncDependencyBoth } from "../../../base-classes/async-dependency-both";
import { Config } from "../../../interfaces/config";
import {
  Registration,
  Reservation,
} from "../../../models/booking/reservation.interface";
import { FeriproGender } from "../../../models/event/feripro-gender.enum";
import { AccessCode } from "../../../models/program/program-extras.interface";
import { Program } from "../../../models/program/program.interface";
import { ConfigService } from "../../../services/config.service";
import { BookingState, EventService } from "../../../services/event.service";
import { ProgramService } from "../../../services/program.service";
import {
  AccountType,
  QuestionService
} from "../../../services/question.service";
import { Tab } from "../../account/profile/profile.page";

@Injectable({
  providedIn: "root",
})
export class CheckStep1Service extends AsyncDependencyBoth {
  private config_data: Config;

  private access_codes: AccessCode[] = [];

  constructor(
    private event_service: EventService,
    private question_service: QuestionService,
    private program_service: ProgramService,
    private config_service: ConfigService,
    private access_code_service: AccessCodeService,
    private payment_service: PaymentService,

    private registration_date_allowed: IsRegistrationDateAllowedPipe
  ) {
    super();

    this.config_data = this.config_service.config;

    this.init(
      event_service,
      question_service,
      program_service,
      access_code_service,
      payment_service,
    );
  }

  protected async onReady(): Promise<void> {
    this.access_code_service
      .get_access_codes$()
      .subscribe((access_codes) => (this.access_codes = access_codes));

    this.set_ready();
  }

  /**
   * checks for profile completeness, required questions, ...
   */
  private check_static_requirements(
    reservations: Reservation[],
    program: Program
  ): boolean {
    return [
      this.check_account_data_complete(reservations),
      this.check_zip_codes(reservations, program),
      this.check_access_code_quota(reservations),
      this.check_profile_questions_answered(reservations),
    ].every((condition) => condition);
  }

  /**
   * Only function used outside of PrioPage
   * should be called on change of:
   *   this.program_service.get_current_program$(),
   *   this.reservation_service.get_reservations$()
   */
  // ! tested
  public is_next_booking_step_allowed(
    reservations: Reservation[],
    program: Program
  ): boolean {
    return [

      // sind überhaupt registrierungen vorhanden?
      reservations?.length,
      reservations?.some((reservation) => reservation?.registrations?.length),

      // test things as on pageload (e.g. profile-related)
      this.check_static_requirements(reservations, program),
    ].every((condition) => condition);
  }

  /**
   * test every single registration and sets error messages in res.participant.warning_messages,
   * @param reservations 
   * @returns reservations, has_error
   */
  public async test_all_registrations_of(reservations: Reservation[]): Promise<{reservations: Reservation[], has_error: boolean }> {
    let has_error = false;

    // test every single registration
    const calc_and_set_error_messages = (
      reservations: Reservation[],
      reservation: Reservation,
      registration: Registration
    ): Promise<Reservation> => {
        return this.is_registration_allowed(reservations, reservation, registration).then(error => {
          if (error) { has_error = true; }
          if (error && !reservation.participant?.warning_messages.includes(error)) {
            reservation.participant?.warning_messages.push(error);
          }
          return reservation;
        });
    }
    
    // update errors
    await reservations.forEach(res => Promise.allSettled(res.registrations.map(reg => calc_and_set_error_messages(reservations, res, reg))));
    return { reservations, has_error };
  }

  /**
   * prüfe ob Programmfragen vollständig
   */
  private check_profile_questions_answered(reservations: Reservation[]): boolean {
    let all_valid = true;

    for (const reservation of reservations) {
      const role: AccountType = Reservation.get_account_type(reservation);
      const questions = this.question_service.get_profile_questions({
        role,
        pk: reservation.pk,
      });

      const questions_valid = questions.every(question => this.question_service.is_valid(question));
      if (!questions_valid) {
        all_valid = false;

        let tab;
        if (role === AccountType.OWNER) { tab = Tab.ACCOUNT; }
        if (role === AccountType.SECOND_LEGAL_GUARDIAN) { tab = Tab.GUARDIAN; }
        if (role === AccountType.CHILD) { tab = Tab.CHILDREN; }

        const message = `<p>Profilfragen für "${reservation.participant?.first_name || ''}" wurden nicht komplett beantwortet.</p><a href="/account/profile?tab=${tab}">Zum Profil</a>`;
        if (reservation.participant && !reservation.participant.warning_messages.includes(message)) {
          reservation.participant.warning_messages.push(message);
        }
      }
    }
    return all_valid;
  }

  /**
   * Prüfe ob relevanten Profildaten vollständig
   */
  // ! tested
  private check_account_data_complete(reservations: Reservation[]): boolean {
    const participant_reservations = reservations.map((reservation) => ({
      reservation,
      participant: reservation?.participant,
    }));

    // see if all participants complete
    return participant_reservations
      .map(({ participant, reservation }) => {
        const account_type: AccountType =
          Reservation.get_account_type(reservation);

        // check if participant is complete
        const data_complete = [
          participant,
          participant?.first_name,
          participant?.last_name,
          participant?.street,
          participant?.city,
          participant?.zip_code,
          participant?.phone,
          (account_type !== AccountType.CHILD &&
            !this.config_data.booking_settings?.parent_booking_allowed) ||
            (participant?.gender?.length && participant?.date_of_birth),
          (account_type === AccountType.CHILD &&
            this.config_data.hide_school_in_profile) ||
            (participant?.school?.length),
        ].every((condition) => condition);

        // Add error message
        let tab;
        if (account_type === AccountType.OWNER) { tab = Tab.ACCOUNT; }
        if (account_type === AccountType.SECOND_LEGAL_GUARDIAN) { tab = Tab.GUARDIAN; }
        if (account_type === AccountType.CHILD) { tab = Tab.CHILDREN; }

        const message = `Die Angaben für den ${this.config_data.wording.Teilnehmer_sg} "${participant?.first_name || ''}" sind nicht vollständig. Bitte prüfen Sie die Angaben im <a href="/account/profile?tab=${tab}">Profil</a>.`;

        if (
          !data_complete && reservation.participant &&
          !reservation.participant.warning_messages.includes(message)
        ) {
          reservation.participant.warning_messages.push(message);
        }

        // return if participant is complete
        return data_complete;
      })
      .every((condition) => condition);
  }

  // ! tested
  private check_zip_codes(
    reservations: Reservation[],
    program: Program
  ): boolean {
    // won't restrict from booking, just for information
    reservations
      .filter((res) => !res.registrations?.length)
      .map((res) => this.check_zip_code(res, program));

    // will restrict from booking, because there are registrations attached
    return reservations
      .filter((res) => res.registrations?.length)
      .map((res) => this.check_zip_code(res, program))
      .every((error) => !error);
  }

  /**
   * Programme können Postleitzahlen angeben.
   * Wenn angegeben, können nur Nutzer mit einer übereinstimmenden PLZ teilnehmen
   */
  // ! tested
  private check_zip_code(reservation: Reservation, program: Program): boolean {
    if (!program?.restricted_zip_codes?.length) {
      return false;
    }

    const zip_codes = program.restricted_zip_codes.map((zip) =>
      parseInt(zip, 10)
    );

    if (zip_codes.includes(parseInt(reservation.participant.zip_code, 10))) {
      return false;
    }

    const message = `Aufgrund Ihrer Wohnlage sind Sie nicht berechtigt an diesem Programm teilzunehmen.`;
    if (reservation.participant && !reservation.participant.warning_messages.includes(message)) {
      reservation.participant.warning_messages.push(message);
    }
    return true;
  }

  /**
   * brauchst du für diese Veranstaltung einen Erzeihungsberechtigten?
   * ist ein Erzeihungsberechtigter vorhanden?
   * manche events benötigen die Teilnahme eines Erzb
   * wenn keiner vorhanden, wird entsprechender Fehler ausgegeben
   * Welche events Erziehungsberechtigten brauchen, steht in der config (events_need_parent_registration = array event_ids)
   */
  // ! tested
  private check_event_need_parent_registration(
    reservations: Reservation[],
    reservation: Reservation,
    registration: Registration
  ): boolean {
    if (
      Reservation.get_account_type(reservation) !== AccountType.CHILD ||
      !this.config_data.booking_settings.events_need_parent_registration
        ?.event_ids?.length
    ) {
      return false;
    }

    // setup
    const parents = reservations.filter(
      (reservation) =>
        reservation && Reservation.get_account_type(reservation) !== AccountType.CHILD
    );
    const event_ids =
      this.config_data.booking_settings.events_need_parent_registration
        .event_ids;

    // event does not need a parent. Ignore it.
    if (!event_ids.includes(registration.event_id)) {
      return false;
    }

    // check if some parent has a registration to the event a parent is needed
    const has_parent = parents.some((parent) => {
      return parent.registrations.some((parent_registration) => {
        return parent_registration.event_id === registration.event_id;
      });
    });

    return !has_parent;
  }

  /**
   * prüfe, ob genug freie Plätze im Zugcode da sind,
   * falls nicht, soll Anmeldung dennoch möglich sein
   * wenn man sich mit 0 Kontingent anmelden kann, gibt es einen Fehler
   */
  // TODO
  private check_access_code_quota(reservations: Reservation[]): boolean {
    return true;
    // let access_key_quota = 0;

    // this.access_codes.forEach((used_code) => {
    //   access_key_quota = used_code.answer?.quota_left;
    //   if (access_key_quota === undefined) { return; }

    //   reservations
    //     .filter(
    //       (reservation) => used_code.code === reservation.participant.access_key
    //     )
    //     .forEach((reservation) => {
    //       const program: Program = this.program_service.get_program(reservation.program_id);

    //       if (used_code.answer.quota_mode === "day") {
    //         let quota_sum = 0;
    //         for (const registration of reservation.registrations) {
    //           const event = this.event_service
    //             .get_events_of(program)
    //             .find((e) => e.event_id === registration.event_id);

    //           // calculate day quota
    //           quota_sum += event.timespans.length || 1;
    //         }

    //         access_key_quota -= quota_sum;
    //       } else {
    //         if (reservation.registrations.length) {
    //           access_key_quota -= 1;
    //         }
    //       }

    //       // together again
    //       if (access_key_quota < 0) {
    //         const code_quota_message = `Der im Profil hinterlegte Code ${used_code.code} hat nicht ausreichend Kontingent.`;
    //         reservation.participant.warning_messages.push(code_quota_message);
    //       }
    //     });
    // });
    // return  access_key_quota >= 0;
  }

  /**
   * copied from GenderRestrictionComponent since this *.class.ts can't import it :(
   */
  private to_verbose_genders(
    allowed_genders: FeriproGender[],
    conversion_map: { m: string; w: string; d: string } = {
      w: "weibliche",
      m: "männliche",
      d: "diverse",
    }
  ): string {
    // transform one-letter gender info into words, eg: 'md' -> ['männliche', 'diverse']
    const verbose_genders = [...new Set([...allowed_genders])]
      .filter((gender) => Object.keys(conversion_map).includes(gender))
      .sort((a, b) => (a === b ? 0 : (a < b ? 1 : -1)))
      .map((gender) => conversion_map[gender]);

    // convert to string with separators ', ' and ' und '
    const last_element = verbose_genders.pop();
    return verbose_genders.length
      ? `${verbose_genders.join(", ")} und ${last_element}`
      : last_element;
  }

  // ! tested
  private async check_max_registrations(
    reservation: Reservation,
    registration: Registration
  ): Promise<boolean> {
    const program = this.program_service.get_program(reservation.program_id);

    // max registrations reached?
    const max_registrations =
      await this.program_service.get_extended_program(program.program_id).then(ex_program => ex_program?.max_registrations_per_participant);
    let current_index = reservation.registrations.indexOf(registration);
    if (current_index === -1) {
      current_index = reservation.registrations.length;
    }

    return (
      (max_registrations || max_registrations === 0) &&
      current_index >= max_registrations
    );
  }

  /**
   * @returns error message as string on error, else undefined if registration is allowed
   * @requires reservation to have all registrations, excluding the passed one for testing
   */
  // ! all checks are tested
  public async is_registration_allowed(
    reservations: Reservation[],
    reservation: Reservation,
    registration: Registration
  ): Promise<string> {
    const program = this.program_service.get_program(reservation.program_id);

    // needs to have event
    const event = this.event_service
      .get_events_of(program)
      .find((e) => e.event_id === registration.event_id);
    if (!event) {
      return `Die dazugehörige Veranstaltung konnte nicht gefunden werden.`;
    }

    if (!this.registration_date_allowed.transform(event, program)) {
      return `Anmeldung für die Veranstaltung "${event.name}" ist aktuell nicht möglich`;
    }

    // is age allowed?
    if (
      !this.is_age_allowed(
        event,
        reservation.participant,
        program.age_check_tolerance
      )
    ) {
      return `Das Alter von ${reservation.participant.first_name} ist für die Veranstaltung "${event.name}" nicht geeignet!`;
    }

    // is gender allowed?
    const verbose_genders = this.to_verbose_genders(event.allowed_genders);
    if (
      event.allowed_genders.length &&
      !event.allowed_genders.includes(reservation.participant.gender)
    ) {
      return `Die Veranstaltung "${event.name}" ist nur für ${verbose_genders} ${this.config_data.wording.Teilnehmer_sg}!`;
    }

    // is participant not disabled or is event accessible?
    if (
      !this.config_data.hide_disabled_in_events_and_booking &&
      reservation.participant.handicap_selection.length &&
      !reservation.participant.handicap_selection
        .every(disability => event.supported_disabilities.some(d => d.value === disability))
    ) {
      return `Die Veranstaltung "${event.name}" ist nicht barrierefrei für ${reservation.participant.first_name}!`;
    }

    if (this.check_zip_code(reservation, program)) {
      return `Aufgrund Ihrer Wohnlage sind Sie nicht berechtigt an diesem Programm teilzunehmen.`;
    }

    if (
      this.check_event_need_parent_registration(
        reservations,
        reservation,
        registration
      )
    ) {
      return `Die Veranstaltung "${registration.name}" benötigt einen Erwachsenen.`;
    }

    if (await this.check_max_registrations(reservation, registration)) {
      return `Die maximale Anzahl Veranstaltungen von ${reservation.participant.first_name} ist überschritten`;
    }

    if (this.event_service.get_booking_state(event) === BookingState.FULL) {
      return `Die Veranstaltung ist restlos ausgebucht`;
    }

    const payment = this.payment_service.get_payment();
    if (this.payment_service.program_payment_is_only_debit() && (!payment?.id || !payment.account_iban || !payment.account_bic)) {
      return `Die Veranstaltung kann nur mit Lastschrift bezahlt werden. ` +
             `Bitte hinterlegen Sie Zahlungsinformationen in Ihrem <a href="/account/profile?tab=payment">Profil</a>. ` +
             `Sie werden am Ende der Buchung dazu aufgefordert der Zahlung zuzustimmen.`;
    }

    return undefined;
  }

  /**
   * @param event
   * @param child
   * @param age_check_tolerance in days
   */
  private is_age_allowed(
    event: Event,
    child: ParticipantModel,
    age_check_tolerance = 0
  ): boolean {
    if (
      !child?.date_of_birth ||
      (!event.min_age && !event.max_age) ||
      !event.start
    ) {
      return true;
    }

    const event_start_date: number = event.start.getTime() || Date.now();
    const child_age_at_start: number =
      event_start_date - new Date(child.date_of_birth).getTime(); // in ms

    const day_in_ms: number = 1000 * 60 * 60 * 24;
    const child_min_age_at_start = new Date(
      child_age_at_start - day_in_ms * age_check_tolerance
    ); // in precision of days
    const child_max_age_at_start = new Date(
      child_age_at_start + day_in_ms * age_check_tolerance
    ); // in precision of days

    const child_min_age = Math.abs(
      child_min_age_at_start.getUTCFullYear() - 1970
    ); //  in precision of years
    const child_max_age = Math.abs(
      child_max_age_at_start.getUTCFullYear() - 1970
    ); //  in precision of years

    // Ask in what cases it is not allowed to participate. Then invert the result.
    // There are 2:
    //    1. A min_age is set on the event, but the child is too young (more precisely:
    //      the max_age of the child is smaller than the minimal age requirement event.min_age)
    //    2. Similar for max_age requirement of event: The the child is too old, if
    //      the min_age of the child is bigger than the maximal age requirement event.max_age)
    return !(
      (event.min_age && child_max_age < event.min_age) ||
      (event.max_age && child_min_age > event.max_age)
    );
  }
}
